<template>
    <div class="how-it-works-page">
        <section id="how-it-works-header">
            <div class="header-wrapper">
                <div class="info-title">
                    <h1 class="header-title">
                        Getting started with
                        <span>unco</span>
                        <br>
                        is simple
                    </h1>
                    <div class="separation-line"/>
                </div>
                <div class="circles-wrapper home-row">
                    <div class="left-circles">
                        <img
                            src="@/assets/HowItWorksPageImages/left-circles.svg">
                    </div>
                    <div class="right-circles">
                        <img
                            src="@/assets/HowItWorksPageImages/right-circles.svg">
                    </div>
                </div>
            </div>
            <div class="concept-info home-row">
                <div class="preview-block">
                    <div class="image-wrapper">
                        <video loop muted playsinline preload="auto">
                            <source :src="require(`@/assets/video/upload-your-concept.mp4`)" type="video/mp4">
                            <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/upload-your-concept.mp4`)">link to the video</a> instead.</p>
                        </video>
                    </div>
                </div>
                <div class="info-block">
                    <div class="title-row">
                        <div class="home-row">
                            <div class="number">
                                1.
                            </div>
                            <h4 class="info-title">
                                Upload your concept
                            </h4>
                        </div>
                        <div class="separation-line"/>
                    </div>
                    <div class="description">
                        <h5>
                            All we need are reference photos or sketches to work with.
                        </h5>
                        <p>
                            Our high-quality 3D designers, experienced in jewelry making itself will help you turn your sketch into a photorealistic render
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section id="review-and-comment">
            <div class="home-row content-wrapper">
                <div class="info-block">
                    <div class="info-title home-row">
                        <div class="number">
                            2.
                        </div>
                        <div>Review and comment</div>
                    </div>
                    <div class="separation-line"/>
                    <div class="description">
                        <h5 class="header">
                            Work alongside our team until you’re completely
                            satisfied
                        </h5>
                        <p>
                            You will be able to review your 3D models before committing to rendering to get the most out of your designs
                        </p>
                    </div>
                </div>
                <div class="preview-block">
                    <div class="image-wrapper">
                        <video loop muted playsinline>
                            <source :src="require(`@/assets/video/review-and-comment.mp4`)" type="video/mp4">
                            <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/review-and-comment.mp4`)">link to the video</a> instead.</p>
                        </video>
                    </div>
                </div>
            </div>
        </section>
        <section id="export-and-use">
            <div class="home-row content-wrapper">
                <div class="preview-block">
                    <div class="image-wrapper">
                        <video loop muted playsinline>
                            <source :src="require(`@/assets/video/export-and-use.mp4`)" type="video/mp4">
                            <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/export-and-use.mp4`)">link to the video</a> instead.</p>
                        </video>
                    </div>
                </div>
                <div class="info-block">
                    <div class="title-wrapper home-row">
                        <div class="number">
                            3.
                        </div>
                        <div>Export & use</div>
                    </div>
                    <div class="separation-line"/>
                    <div class="description">
                        <h5 class="header">
                            Getting high quality digital media is easy
                        </h5>
                        <p>Based on your format preferences, we will get your final renderings ready to be used wherever you need them</p>
                    <!--                        <button>See How</button>-->
                    </div>
                </div>
            </div>
        </section>
        <section id="flexible-app">
            <div class="home-row content-wrapper">
                <div class="info-block">
                    <div class="title-wrapper">
                        <div>Flexible <br> Applications</div>
                    </div>
                    <div class="separation-line"/>
                    <div class="description">
                        <h5 class="header">
                            Simple product management,<br> built to collaborate
                        </h5>
                        <p>
                            Your renders can be ready for configurators, pack shots or any other media that suits your needs
                        </p>
                        <!--                        <button>-->
                        <!--                            Learn More-->
                        <!--                        </button>-->
                    </div>
                </div>
                <div class="preview">
                    <div class="image-wrapper">
                        <video loop muted playsinline>
                            <source :src="require(`@/assets/video/any-angle.mp4`)" type="video/mp4">
                            <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/any-angle.mp4`)">link to the video</a> instead.</p>
                        </video>
                        <div class="image-label">Any <strong>angle</strong></div>
                    </div>
                    <div class="image-wrapper">
                        <div class="image-label">Any <strong>background</strong></div>
                        <video loop muted playsinline>
                            <source :src="require(`@/assets/video/any-background.mp4`)" type="video/mp4">
                            <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/any-background.mp4`)">link to the video</a> instead.</p>
                        </video>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "page-how-it-works",
        data: () => ({
            observer: null
        }),
        mounted() {
            this.observer = new IntersectionObserver(this.onElementObserved, {
                threshold: 1
            });

            document.querySelectorAll("video").forEach(lazyVideo => {
                this.observer.observe(lazyVideo);
            });
        },
        beforeDestroy() {
            this.observer.disconnect();
        },
        methods: {
            onElementObserved(entries) {
                entries.forEach(({ target, isIntersecting }) => {
                    if (!isIntersecting) {
                        return;
                    }

                    target.play();
                    this.observer.unobserve(target);
                });
            }
        }
    };
</script>

<style scoped lang="scss" src="./style.scss"></style>
